const routes = [
  {
    index: true,
    label: 'Mistress Iris Steele',
    path: '/',
  },
  {
    label: 'About',
    path: '/about',
  },
  {
    label: 'Gallery',
    path: '/gallery',
  },
  {
    label: 'Sessions',
    path: '/sessions',
  },
];

export default routes;
