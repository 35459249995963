import { useState, useEffect } from 'react';

const useAgeVerification = () => {
  const [isOver18, setIsOver18] = useState(null);

  useEffect(() => {
    const storedVerification = localStorage.getItem('isOver18');
    if (storedVerification) {
      setIsOver18(storedVerification === 'true');
    }
  }, []);

  const verifyOver18 = () => {
    setIsOver18(true);
    localStorage.setItem('isOver18', 'true');
  };

  const redirectUnder18 = () => {
    window.location.href = 'https://www.google.com';
  };

  return { isOver18, verifyOver18, redirectUnder18 };
};

export default useAgeVerification;
