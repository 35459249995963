import React from 'react';
import useAgeVerification from './useAgeVerification'; // Import the custom hook

const AgeVerification = () => {
  const { isOver18, verifyOver18, redirectUnder18 } = useAgeVerification();

  const overlayStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: isOver18 ? 'none' : 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999999,
    backdropFilter: 'blur(10px)',
  };

  const popupStyles = {
    background: 'white',
    padding: '20px',
    textAlign: 'center',
    borderRadius: '8px',
  };

  return !isOver18 ? (
    <div className="age-verification-overlay" style={overlayStyles}>
      <div className="age-verification-popup" style={popupStyles}>
        <p style={{ margin: '20px' }}>This website contains explicit material. Please verify your age to enter.</p>
        <div className="buttons">
          <button type="button" onClick={redirectUnder18}>
            I am under 18
          </button>
          <button type="button" onClick={verifyOver18}>
            I am over 18
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default AgeVerification;
